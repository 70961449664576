<template>
  <v-card>
    <v-card-title>
      <span> Contratos </span>
    </v-card-title>

    <v-data-table
      :loading="loading"
      loading-text="Carregando dados... Por favor, aguarde"
      :headers="headers"
      :items="itemsTable"
      :items-per-page="10"
    >
      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiDelete, mdiEye, mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters],

  data() {
    return {
      headers: [
        { text: 'EMISSOR', value: 'user.name', align: 'left' },
        { text: 'DESCRIÇÃO', value: 'text', align: 'center' },
        { text: 'EMISSÃO', value: 'created_at', align: 'center' },
        { text: 'VENDEDOR', value: 'contract.vendor_name', align: 'center' },
        { text: 'REFERÊNCIA', value: 'contract.reference', align: 'center' },
        { text: 'Nº. CONTRATO', value: 'contract.contract_number', align: 'center' },
      ],

      itemsTable: [],
      loading: false,

      icons: {
        mdiEye,
        mdiPencil,
        mdiDelete,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.loading = true

      await axiosIns.get('api/v1/integration/history_deleted_contract/index').then(response => {
        this.itemsTable = response.data.data
        this.loading = false
      })
    },
  },
}
</script>
